import { createRouter, createWebHistory } from 'vue-router'
import { getCurrentUser } from 'vuefire'
import { getAuth, signOut } from "firebase/auth";
import { useUserStore } from '@/stores/userStore'
import { useAlertStore } from '@/stores/alertStore'
import { getUser } from '@/services/userAuth';

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routerOptions = [
  {
    folder: "Recipes",
    routes: [
      { path: '/recipes', component: "RecipesList", meta: { requiresAuth: true } },
      { path: '/recipe/add', component: "RecipeCreate", meta: { requiresAuth: true } },
      { path: '/recipe/:id', component: "RecipeView", meta: { requiresAuth: true }, props: true },
      { path: '/recipe/cooking/:id', component: "RecipeCookingModeView", meta: { requiresAuth: true }, props: true },
      { path: '/recipe/edit/:id', component: "RecipeEdit", meta: { requiresAuth: true }, props: true },
      { path: '/recipe/duplicate/:id', component: "RecipeDuplicate", meta: { requiresAuth: true }, props: true },
      { path: '/recipe/import', component: "RecipeImport", meta: { requiresAuth: true }, props: route => ({ requestRecipe: route.query.requestRecipe }) },
      { path: '/recipe/share', component: "RecipeShare", meta: { requiresAuth: false }, props: route => ({ requestRecipe: route.query.requestRecipe }) },
    ],
  },
  {
    folder: "Groceries",
    routes: [
      { path: '/groceries', component: "GroceriesList", meta: { requiresAuth: true } },
    ],
  },
  {
    folder: "House",
    routes: [
      { path: '/house', component: "HouseView", meta: { requiresAuth: true } },
      { path: '/ingredients', component: "IngredientsList", meta: { requiresAuth: true } },
      { path: '/my-account', component: "UserProfile", meta: { requiresAuth: true } },
      { path: '/my-house', component: "MyHouseView", meta: { requiresAuth: true } },
    ],
  },
  {
    folder: "Misc",
    routes: [
      { path: '/signup/:house_id', component: "SignUp", props: true },
      { path: '/login', component: "LogIn" },
      { path: '/not-accepted-yet', component: "NotAcceptedYet" },
      { path: '/rejected', component: "RejectedView" },
      { path: '/', component: "HomeView" },
    ],
  },
  {
    folder: "script",
    routes: [
  //     { path: '/script_ingredients', component: "ScriptIngredients", meta: { requiresAuth: true, folder: "script" }, props: true },
      { path: '/migrate', component: "Migrate", meta: { requiresAuth: true }, props: true },
    ],
  },
]

const routes = []
routerOptions.map(f => routes.push(...f.routes.map(route => {
  return {
    ...route,
    component: () => import(`../pages/${f.folder}/${route.component}.vue`),
    name: route.component
  };
})));

routes.push(
  { path: '/logout', 
    component: () => import(`../pages/Misc/LogOut.vue`),
    beforeEnter: () => {
      const auth = getAuth();
      signOut(auth).then(() => {
        const userStore = useUserStore();
        userStore.logout();
        return {
          path: '/'
        }
      }).catch((error) => {
        const alertStore = useAlertStore();
        alertStore.error(error)
      });
    },
  }
);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      console.log(to.hash)
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ el: to.hash })
        }, 300)
      })
    }
    // always scroll to top
    return { top: 0 }
  },
})
  
router.beforeEach(async (to) => {
  // routes with `meta: { requiresAuth: true }` will check for the users, others won't
  if (to.meta.requiresAuth) {

    // better than getAuth().currentUser that doesn't keep login at refresh
    const currentUser = await getCurrentUser();

    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: '/login',
        query: {
          // we keep the current path in the query so we can redirect to it after login
          // with `router.push(route.query.redirectTo || '/')`
          redirectTo: to.fullPath,
        },
      }
    }
    const user = await getUser(currentUser.uid, currentUser.photoURL)

    if (!user) {
      return {
        path: '/rejected',
      }
    }

    if (!user?.accepted) {
      return {
        path: '/not-accepted-yet',
      }
    }
  }
})
  
router.beforeResolve(async (to, from, next) => {
  const currentUser = await getCurrentUser();
  if (currentUser) {
    const userStore = useUserStore();
    await userStore.setFromAuth();
  }
  next()
})

export default router